import axios from "axios";
import jwt_decode, { type JwtPayload } from "jwt-decode";

let isLogoutErrorShown = false; //Flag to track whether the logout error has been shown

export default defineNuxtPlugin((nuxtApp) => {
  const { ENVIRONMENT, PROD_BASE_URL, DEV_BASE_URL } =
    useRuntimeConfig().public;

  // Create a cancellation token source
  const cancelTokenSource = axios.CancelToken.source();

  let sessionExpired: string;
  if (import.meta.client) {
    sessionExpired = JSON.parse(
      sessionStorage.getItem("spv-session-expired") ?? "true",
    );
  }

  const defaultUrl =
    ENVIRONMENT === "production" ? PROD_BASE_URL : DEV_BASE_URL;

  const api = axios.create({
    baseURL: defaultUrl,
    headers: {
      common: {},
    },
  });

  const getPathAndQueries = (url: string) => {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname + parsedUrl.search;
  };

  const saveCurrentUrlState = () => {
    const currentUrl = window.location.href;
    sessionStorage.setItem("current-url", getPathAndQueries(currentUrl));
  };

  // Clear all Cookies & Reset Store
  const resetState = () => {
    // Clear all Cookies & Reset Store

    if (import.meta.client) {
      sessionStorage.removeItem("sproutly_africa_state");
      sessionStorage.removeItem("spv-token");
      sessionStorage.removeItem("spv-login-state");
      sessionStorage.removeItem("spv-session-expired");
    }

    saveCurrentUrlState();

    if (!isLogoutErrorShown) {
      if (window.location.pathname !== "/login") {
        // Show error message only if it hasn't been shown before
        useNuxtApp().$toast.error("Session expired. Please log in again!!!");
        isLogoutErrorShown = true; // Set the flag to true after showing the error

        // Redirect to login page
        window.location.href = "/login";
      }
    }
  };

  // Add an interceptor for outgoing requests
  api.interceptors.request.use(async (config: any) => {
    if (!navigator.onLine) {
      useNuxtApp().$toast.error(
        "No internet connection. Please check your connection and try again.",
      );
      return Promise.reject(new axios.Cancel("No internet connection"));
    }
    // Pass the cancellation token to the request config
    config.cancelToken = cancelTokenSource.token;

    // GET TOKEN TO BE USED GLOBALLY
    let token: string | null;

    if (import.meta.client) {
      token = sessionStorage.getItem("spv-token");
      let hasTokenExpired = false;

      if (token) {
        const decoded_token: JwtPayload | undefined = jwt_decode(token);

        const dateNow = new Date();

        if (
          decoded_token &&
          decoded_token.exp &&
          decoded_token.exp < dateNow.getTime() / 1000
        ) {
          hasTokenExpired = true;
        }
      }

      if (hasTokenExpired) {
        const refreshToken = async () => {
          try {
            nuxtApp.callHook("page:start");
            const response = await axios.get(
              `${defaultUrl}/users/auth/refresh`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );

            const { data } = response.data;
            if (import.meta.client) {
              sessionStorage.setItem("spv-token", data.token);
            }
            config.headers["Authorization"] = `Bearer ${data.token}`;
            return config;
          } catch (error: any) {
            if (error.response.status === 401) {
              // Clear all Cookies & Reset Store
              resetState();
            }
          } finally {
            nuxtApp.callHook("page:finish");
          }
        };

        await refreshToken();
      } else {
        // MODIFYING HEADERS
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      }
    }
  });

  // Add an interceptor for responses
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!sessionExpired) {
        // Handle specific error responses

        if (error.response.status === 401) {
          // Cancel all other requests upon encountering a 401 error
          cancelTokenSource.cancel(
            "Request canceled due to authentication error",
          );
          // Clear all Cookies & Reset Store
          resetState();
          return;
        }

        if (
          error.response.status === 500 &&
          error.response.data.message === "Server error"
        ) {
          // Clear all Cookies & Reset Store
          resetState();
        }

        if (error.response.status === 503) {
          // Clear all Cookies & Reset Store
          resetState();
        }

        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );

  return {
    provide: {
      axios: api,
      resetState,
    },
  };
});
