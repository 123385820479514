import { useStore } from "~/store";
import { routeRoles } from "~/utils/roles";

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useStore();
  const user_role = store?.user_role?.slug ?? "";
  const requiredRole = routeRoles[to.path] ?? null; // Get roles dynamically

  if (import.meta.client) {
    const token = sessionStorage.getItem("spv-token");
    const loginState = sessionStorage.getItem("spv-login-state");
    const isAuthenticated = token && loginState;

    // Redirect to login if user is not authenticated and trying to access dashboard pages
    if (!isAuthenticated && to.meta.layout === "dashboard") {
      return navigateTo("/login");
    }

    // Redirect to login if accessing onboarding without authentication
    if (!isAuthenticated && to.path.startsWith("/dashboard/onboarding")) {
      return navigateTo("/login");
    }

    // If the route has role restrictions, check user authorization
    if (
      requiredRole &&
      requiredRole.length > 0 &&
      !requiredRole.includes(user_role)
    ) {
      return navigateTo("/dashboard/unauthorized");
    }
  }
});
