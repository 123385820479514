
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexD8Kf5nPoGkO_45mPRcy0kXxYdHgQUU8jSfv0icJqY11_45oMeta } from "/src/pages/index.vue?macro=true";
import { default as login7COOQeYKQwisHoj2xkTxmCi9HyQxCOyTj3KOsyypuxYMeta } from "/src/pages/login.vue?macro=true";
import { default as sign_45upaXiaAkEaUzg4iMFWoB54HIemMDeKBUAZrVBqW1yGfNoMeta } from "/src/pages/sign-up.vue?macro=true";
import { default as mainPdAWrHetXQaGt1XSySq0BtRSUe7Ba6udGwxMPLOJBZAMeta } from "/src/pages/dashboard/main.vue?macro=true";
import { default as reset_45passwordQicFl_nyCWAQXHHDt8x0HUyvKc02qOXAFiNcMEUFgJwMeta } from "/src/pages/reset-password.vue?macro=true";
import { default as indexeFnmdfir3BX6tba5aYsHvhoS_45_36_dsmSn9WmzPm8zoMeta } from "/src/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordYOCeOS1Dyg062ghez9joZaYclMsTOBZFXlpaww_UxFUMeta } from "/src/pages/forgot-password.vue?macro=true";
import { default as supportOJnTiMHtuREwUxmaesg_0ZlRX5bKXhpJ9LsciA9fnM8Meta } from "/src/pages/dashboard/support.vue?macro=true";
import { default as overviewoj5_v7x9XmLNgjJVtxCyOoWFopGH7B4IH1mtOApI90YMeta } from "/src/pages/dashboard/overview.vue?macro=true";
import { default as irecovery3_qvXTyHKnVXcFmFR1L2hbeKjX2YWg_45PKIBxYicDW_45gMeta } from "/src/pages/dashboard/irecovery.vue?macro=true";
import { default as new_45schooltfiG_45_45ua2wQ60IpCbrUSTOUr03iL0HqChyfRu_455R994Meta } from "/src/pages/dashboard/new-school.vue?macro=true";
import { default as overview_45newHzK82Cx11p2iiSx_451bR16_WOJcHAM6BD0pKB_VbRiE0Meta } from "/src/pages/dashboard/overview-new.vue?macro=true";
import { default as unauthorizedLYdmY8DaUjg3bxw6HIJYhEeY7stFsqurDG2u_45J1dSHsMeta } from "/src/pages/dashboard/unauthorized.vue?macro=true";
import { default as storeCMRkdvfdF_d5tKZEfV_45lUj5DYhvSOUSTOfbjL2EjnHwMeta } from "/src/pages/dashboard/bursary/store.vue?macro=true";
import { default as indexHtqqx7IH9VJDuz9DZiWvIRqiMNaRbEhKiW8OKYiUwRAMeta } from "/src/pages/dashboard/paylink/index.vue?macro=true";
import { default as indexlwC25u76utKvKqrRbwJn5_6GKA_456lBydH9kfvEcKBv4Meta } from "/src/pages/dashboard/rewards/index.vue?macro=true";
import { default as perksWHahaRXZMU5bgunDkIL32bWkK2JeEKWzIhWAzC5L6FYMeta } from "/src/pages/dashboard/rewards/perks.vue?macro=true";
import { default as verify_45tokenEhjJ7y0J5TYDoYFb4Hj5ZqQqiMVkQG6fGyDzsARJDAIMeta } from "/src/pages/invitation/verify-token.vue?macro=true";
import { default as indexnRTNnpaFL2geKy9thel7DL3BQwFxlc0INDd3JMK6LNkMeta } from "/src/pages/dashboard/settings/index.vue?macro=true";
import { default as billingofzNHf4eaEt_45Liz_45kcbwl2SB5MBashS0hr61KvtOmLoMeta } from "/src/pages/dashboard/bursary/billing.vue?macro=true";
import { default as payoutYLaHp7wMIy3d8_yEj3nFAUQmf_PM2N6hAKPDS2kqj_45wMeta } from "/src/pages/dashboard/settings/payout.vue?macro=true";
import { default as invoicesrC7DoEPZdzUZ_45kvmgXLOS2BtB2FQ5DNMiTyUHr6uRq4Meta } from "/src/pages/dashboard/bursary/invoices.vue?macro=true";
import { default as indexgowQwEvkDDcyqTWfAKut3aNFILlD7wgDkMozH2ZN9EgMeta } from "/src/pages/dashboard/onboarding/index.vue?macro=true";
import { default as tranchea2xM7mTPDNy8KAXkHxSdX68_45Bp0kY_wnod5kLlxGwesMeta } from "/src/pages/dashboard/settings/tranche.vue?macro=true";
import { default as referrals0el04y_45z2ZEOoJjr4QzBLpJvxY0tYKrj4n1_45fbouygoMeta } from "/src/pages/dashboard/rewards/referrals.vue?macro=true";
import { default as discountpvpVDxCLr_45DLQrBUn4bZ5z0yjv93DDrKMmocx_45GAlpkMeta } from "/src/pages/dashboard/settings/discount.vue?macro=true";
import { default as securitygAJ4MYsy6UUtNRI963iTpN_x8QveQ_SLXxWOaj_45zl5YMeta } from "/src/pages/dashboard/settings/security.vue?macro=true";
import { default as _91id_93c9KJVCayQLzcoKjBTgF89HT1NCpdzyplNInOlKtHrcIMeta } from "/src/pages/dashboard/admin/notices/[id].vue?macro=true";
import { default as indexjP9CM_45UjRBZ2s7tmrR1TT4f3jzJpb94DC5WQ0pXxRTkMeta } from "/src/pages/dashboard/admin/notices/index.vue?macro=true";
import { default as indexSqe4Yhgye6KIsg_45isoy_nUJ3rhdBqj37_Rwu1Sh8_uwMeta } from "/src/pages/dashboard/finance/cowri/index.vue?macro=true";
import { default as transactionmkBiGslkmMfkTgzcaeTI_45TPeqJm5ojqXzzDByPLk6TYMeta } from "/src/pages/dashboard/finance/transaction.vue?macro=true";
import { default as compliancec57He4qj05L6PbRvViw9jv_45AxGJsnoTwzMMeJZLnaqQMeta } from "/src/pages/dashboard/settings/compliance.vue?macro=true";
import { default as indexlljOx9FUmReuRkuNMU_arLIY_45S_45ohqSmoTvJwHDRKF8Meta } from "/src/pages/dashboard/settings/team/index.vue?macro=true";
import { default as roles2Hr1KETAnpZIUa7yrG3GX8DEeLESO9_45ALpRzXtC6cSEMeta } from "/src/pages/dashboard/settings/team/roles.vue?macro=true";
import { default as draftsmCLKyR1oW_9zvKWmIM_45VpBclX4Cw51iRsa_45oRTbGPpgMeta } from "/src/pages/dashboard/admin/notices/drafts.vue?macro=true";
import { default as cashKBvbGUtIZ45PHlgYj4hzlODy4bWlMGyIu66uJxB_45_45HMMeta } from "/src/pages/dashboard/finance/wallets/cash.vue?macro=true";
import { default as _91paylink_id_93aDd7GZLY58Dn0VhBh6zav1JOBh2FXveuTNuCPYQ0NBIMeta } from "/src/pages/dashboard/paylink/[paylink_id].vue?macro=true";
import { default as class_45indexlZKSClaO_45hXjHAA65VAThIZpLofuWuBdmMutNjs2vKoMeta } from "/src/pages/dashboard/settings/class-index.vue?macro=true";
import { default as _91id_93otnYLbKvklJ1XgZt1KIGBGPnajgBs_xHFIWk5o4R2eEMeta } from "/src/pages/dashboard/settings/groups/[id].vue?macro=true";
import { default as indexHC2pMwrnxgB2BXWh8yjZ7MrphB4X8vFTZ11VA_r93NgMeta } from "/src/pages/dashboard/finance/wallets/index.vue?macro=true";
import { default as point_45historyN0vyiEOchNpPxc3q4SV3LmoJSzrbccvBj34rtQW6m0oMeta } from "/src/pages/dashboard/rewards/point-history.vue?macro=true";
import { default as address_45book3LqU7akQa_453PgqtqvXYn5xl4pVTx4zFM10ZOJJVT2McMeta } from "/src/pages/dashboard/settings/address-book.vue?macro=true";
import { default as indexlqZbK3sWptzmS3MR3up_45n05B_JXMlJC8ZKBdM3iH4p0Meta } from "/src/pages/dashboard/settings/groups/index.vue?macro=true";
import { default as subscriptionbpv_T6ZHRcz_45N_45BU44fpjY1xLAuaUv7x_nYBK2ZIv7IMeta } from "/src/pages/dashboard/settings/subscription.vue?macro=true";
import { default as billing_45accountsIXwmtHqp4tkA8vIZq4jiSW6u_455XRDew5CSRfDCdzIh8Meta } from "/src/pages/dashboard/admin/billing-accounts.vue?macro=true";
import { default as create_45paylinkcQcfPDd8Ckn4QREgCMLGv2rxNLr6kHByWiqn_DANUTsMeta } from "/src/pages/dashboard/paylink/create-paylink.vue?macro=true";
import { default as authenticatoru3HKZYFfcM3TQZbz_45kw8FMnDhe4oTIZuYinE1p52ip4Meta } from "/src/pages/dashboard/payments/authenticator.vue?macro=true";
import { default as indexwU4P0tjcSgamhqcBlAt_452w95Yaxik409pDULp94gIIQMeta } from "/src/pages/dashboard/payments/invoices/index.vue?macro=true";
import { default as create_45schoolbyqmaDadu9eZ83nWW0gsQvSrchxOYbsps5553NSQp2kMeta } from "/src/pages/dashboard/onboarding/create-school.vue?macro=true";
import { default as indexkWwT1Y4AmP30gF6ZxxovksbLTbW7Ziw5Gt5KevL1Gp8Meta } from "/src/pages/dashboard/bursary/collections/index.vue?macro=true";
import { default as index6dyEHYRyvmyWkSLN0TFQVUzLX0rVHbrSLY53xam6vKAMeta } from "/src/pages/dashboard/payments/settlement/index.vue?macro=true";
import { default as migrationao_uPJGekUIDgPUkKqZu1TCrFxSqAILBB5Eou9KoZhgMeta } from "/src/pages/dashboard/settings/groups/migration.vue?macro=true";
import { default as _91id_93_VX3BLuTZzRQEYXezjvJ5TiKACOYPLKK_45Ha2wrgQBrMMeta } from "/src/pages/dashboard/settings/staff-groups/[id].vue?macro=true";
import { default as _91id_93OE0gXU_1AQaYFrCKj7dxJuToeWL_45RfTMPV1x6zKOBOMMeta } from "/src/pages/dashboard/admin/billing-managers/[id].vue?macro=true";
import { default as offer_45updatesxXRctbp_CJAdiVbpPB419VjKiOoLzAFpoHnpfXhKuH0Meta } from "/src/pages/dashboard/finance/cowri/offer-updates.vue?macro=true";
import { default as indexUs7FqKm3_4528QmA684IJiQ1nu_JK9ofXHTMkVwYWgiKoMeta } from "/src/pages/dashboard/settings/staff-groups/index.vue?macro=true";
import { default as index_45lGH1b_45RTF_45RulYe1ji1l1s3AXx8Rz7dyk_45yjA4D39MMeta } from "/src/pages/dashboard/admin/billing-managers/index.vue?macro=true";
import { default as indexQJdxsg5kj2_J_LNcfBoyXmJVN7dzBfVHAuKDcXfn5SMMeta } from "/src/pages/dashboard/bursary/billing-system/index.vue?macro=true";
import { default as invoicesJTcmPPSx8uWhwP_8YOn4dO0fMOFI_45V7Ftn9dsoBUmEwMeta } from "/src/pages/dashboard/bursary/collections/invoices.vue?macro=true";
import { default as current_45credit_85tesC6nDNTOeMJWcEGG8zJvSLksjHCHfEwHNeiqdwMeta } from "/src/pages/dashboard/finance/cowri/current-credit.vue?macro=true";
import { default as indexDJtg9yj5Iy62CKlsTiKJwyKx79kPIf6P0yUGZ2hFKsQMeta } from "/src/pages/dashboard/settings/vendor-groups/index.vue?macro=true";
import { default as edit_45invoiceWYhGHJksTobZEqU_457S39R9r0IOj_45ghT0y_45_tnPPZpa4Meta } from "/src/pages/dashboard/bursary/collections/edit-invoice.vue?macro=true";
import { default as discount_45and_45scholarshipP3J7Ouhkmjqr7XOO61Tn5_45z4gzsZm2JURiPHvwiRRGYMeta } from "/src/pages/dashboard/bursary/discount-and-scholarship.vue?macro=true";
import { default as create_45invoicewLMUTmtqCMMQYmeEVYJLsy0tgm6UQ0klEVfih8pJizwMeta } from "/src/pages/dashboard/bursary/collections/create-invoice.vue?macro=true";
import { default as settlement_45listXIZVcsjCuhssxuZkLq2IQIqd5yIO2T269vw2xhhImjMMeta } from "/src/pages/dashboard/payments/settlement/settlement-list.vue?macro=true";
import { default as billing_45itemsYciZ9INmOTKfdU18Q5nyAyCcAa0LExNCwCR2gfsEG2AMeta } from "/src/pages/dashboard/bursary/billing-system/billing-items.vue?macro=true";
import { default as add_45billing_45categoryKn62Bw_aotXKPhzEBDPXWPKlbOYQiKu5ZkgnlpG54Z4Meta } from "/src/pages/dashboard/bursary/billing-system/add-billing-category.vue?macro=true";
import { default as edit_45billing_45category_45hm3l_45xYx4VSguJQ5dO2CDAQe9KvGIcGa3r2ptuFMdEMeta } from "/src/pages/dashboard/bursary/billing-system/edit-billing-category.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7COOQeYKQwisHoj2xkTxmCi9HyQxCOyTj3KOsyypuxYMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upaXiaAkEaUzg4iMFWoB54HIemMDeKBUAZrVBqW1yGfNoMeta || {},
    component: () => import("/src/pages/sign-up.vue")
  },
  {
    name: "dashboard-main",
    path: "/dashboard/main",
    meta: mainPdAWrHetXQaGt1XSySq0BtRSUe7Ba6udGwxMPLOJBZAMeta || {},
    component: () => import("/src/pages/dashboard/main.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexeFnmdfir3BX6tba5aYsHvhoS_45_36_dsmSn9WmzPm8zoMeta || {},
    component: () => import("/src/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "dashboard-support",
    path: "/dashboard/support",
    meta: supportOJnTiMHtuREwUxmaesg_0ZlRX5bKXhpJ9LsciA9fnM8Meta || {},
    component: () => import("/src/pages/dashboard/support.vue")
  },
  {
    name: "dashboard-overview",
    path: "/dashboard/overview",
    meta: overviewoj5_v7x9XmLNgjJVtxCyOoWFopGH7B4IH1mtOApI90YMeta || {},
    component: () => import("/src/pages/dashboard/overview.vue")
  },
  {
    name: "dashboard-irecovery",
    path: "/dashboard/irecovery",
    meta: irecovery3_qvXTyHKnVXcFmFR1L2hbeKjX2YWg_45PKIBxYicDW_45gMeta || {},
    component: () => import("/src/pages/dashboard/irecovery.vue")
  },
  {
    name: "dashboard-new-school",
    path: "/dashboard/new-school",
    component: () => import("/src/pages/dashboard/new-school.vue")
  },
  {
    name: "dashboard-overview-new",
    path: "/dashboard/overview-new",
    meta: overview_45newHzK82Cx11p2iiSx_451bR16_WOJcHAM6BD0pKB_VbRiE0Meta || {},
    component: () => import("/src/pages/dashboard/overview-new.vue")
  },
  {
    name: "dashboard-unauthorized",
    path: "/dashboard/unauthorized",
    component: () => import("/src/pages/dashboard/unauthorized.vue")
  },
  {
    name: "dashboard-bursary-store",
    path: "/dashboard/bursary/store",
    meta: storeCMRkdvfdF_d5tKZEfV_45lUj5DYhvSOUSTOfbjL2EjnHwMeta || {},
    component: () => import("/src/pages/dashboard/bursary/store.vue")
  },
  {
    name: "dashboard-paylink",
    path: "/dashboard/paylink",
    meta: indexHtqqx7IH9VJDuz9DZiWvIRqiMNaRbEhKiW8OKYiUwRAMeta || {},
    component: () => import("/src/pages/dashboard/paylink/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexlwC25u76utKvKqrRbwJn5_6GKA_456lBydH9kfvEcKBv4Meta || {},
    component: () => import("/src/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-rewards-perks",
    path: "/dashboard/rewards/perks",
    meta: perksWHahaRXZMU5bgunDkIL32bWkK2JeEKWzIhWAzC5L6FYMeta || {},
    component: () => import("/src/pages/dashboard/rewards/perks.vue")
  },
  {
    name: "invitation-verify-token",
    path: "/invitation/verify-token",
    component: () => import("/src/pages/invitation/verify-token.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: indexnRTNnpaFL2geKy9thel7DL3BQwFxlc0INDd3JMK6LNkMeta || {},
    component: () => import("/src/pages/dashboard/settings/index.vue")
  },
  {
    name: "dashboard-bursary-billing",
    path: "/dashboard/bursary/billing",
    meta: billingofzNHf4eaEt_45Liz_45kcbwl2SB5MBashS0hr61KvtOmLoMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing.vue")
  },
  {
    name: "dashboard-settings-payout",
    path: "/dashboard/settings/payout",
    meta: payoutYLaHp7wMIy3d8_yEj3nFAUQmf_PM2N6hAKPDS2kqj_45wMeta || {},
    component: () => import("/src/pages/dashboard/settings/payout.vue")
  },
  {
    name: "dashboard-bursary-invoices",
    path: "/dashboard/bursary/invoices",
    meta: invoicesrC7DoEPZdzUZ_45kvmgXLOS2BtB2FQ5DNMiTyUHr6uRq4Meta || {},
    component: () => import("/src/pages/dashboard/bursary/invoices.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    component: () => import("/src/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-settings-tranche",
    path: "/dashboard/settings/tranche",
    meta: tranchea2xM7mTPDNy8KAXkHxSdX68_45Bp0kY_wnod5kLlxGwesMeta || {},
    component: () => import("/src/pages/dashboard/settings/tranche.vue")
  },
  {
    name: "dashboard-rewards-referrals",
    path: "/dashboard/rewards/referrals",
    meta: referrals0el04y_45z2ZEOoJjr4QzBLpJvxY0tYKrj4n1_45fbouygoMeta || {},
    component: () => import("/src/pages/dashboard/rewards/referrals.vue")
  },
  {
    name: "dashboard-settings-discount",
    path: "/dashboard/settings/discount",
    meta: discountpvpVDxCLr_45DLQrBUn4bZ5z0yjv93DDrKMmocx_45GAlpkMeta || {},
    component: () => import("/src/pages/dashboard/settings/discount.vue")
  },
  {
    name: "dashboard-settings-security",
    path: "/dashboard/settings/security",
    meta: securitygAJ4MYsy6UUtNRI963iTpN_x8QveQ_SLXxWOaj_45zl5YMeta || {},
    component: () => import("/src/pages/dashboard/settings/security.vue")
  },
  {
    name: "dashboard-admin-notices-id",
    path: "/dashboard/admin/notices/:id()",
    meta: _91id_93c9KJVCayQLzcoKjBTgF89HT1NCpdzyplNInOlKtHrcIMeta || {},
    component: () => import("/src/pages/dashboard/admin/notices/[id].vue")
  },
  {
    name: "dashboard-admin-notices",
    path: "/dashboard/admin/notices",
    meta: indexjP9CM_45UjRBZ2s7tmrR1TT4f3jzJpb94DC5WQ0pXxRTkMeta || {},
    component: () => import("/src/pages/dashboard/admin/notices/index.vue")
  },
  {
    name: "dashboard-finance-cowri",
    path: "/dashboard/finance/cowri",
    meta: indexSqe4Yhgye6KIsg_45isoy_nUJ3rhdBqj37_Rwu1Sh8_uwMeta || {},
    component: () => import("/src/pages/dashboard/finance/cowri/index.vue")
  },
  {
    name: "dashboard-finance-transaction",
    path: "/dashboard/finance/transaction",
    meta: transactionmkBiGslkmMfkTgzcaeTI_45TPeqJm5ojqXzzDByPLk6TYMeta || {},
    component: () => import("/src/pages/dashboard/finance/transaction.vue")
  },
  {
    name: "dashboard-settings-compliance",
    path: "/dashboard/settings/compliance",
    meta: compliancec57He4qj05L6PbRvViw9jv_45AxGJsnoTwzMMeJZLnaqQMeta || {},
    component: () => import("/src/pages/dashboard/settings/compliance.vue")
  },
  {
    name: "dashboard-settings-team",
    path: "/dashboard/settings/team",
    meta: indexlljOx9FUmReuRkuNMU_arLIY_45S_45ohqSmoTvJwHDRKF8Meta || {},
    component: () => import("/src/pages/dashboard/settings/team/index.vue")
  },
  {
    name: "dashboard-settings-team-roles",
    path: "/dashboard/settings/team/roles",
    meta: roles2Hr1KETAnpZIUa7yrG3GX8DEeLESO9_45ALpRzXtC6cSEMeta || {},
    component: () => import("/src/pages/dashboard/settings/team/roles.vue")
  },
  {
    name: "dashboard-admin-notices-drafts",
    path: "/dashboard/admin/notices/drafts",
    meta: draftsmCLKyR1oW_9zvKWmIM_45VpBclX4Cw51iRsa_45oRTbGPpgMeta || {},
    component: () => import("/src/pages/dashboard/admin/notices/drafts.vue")
  },
  {
    name: "dashboard-finance-wallets-cash",
    path: "/dashboard/finance/wallets/cash",
    meta: cashKBvbGUtIZ45PHlgYj4hzlODy4bWlMGyIu66uJxB_45_45HMMeta || {},
    component: () => import("/src/pages/dashboard/finance/wallets/cash.vue")
  },
  {
    name: "dashboard-paylink-paylink_id",
    path: "/dashboard/paylink/:paylink_id()",
    meta: _91paylink_id_93aDd7GZLY58Dn0VhBh6zav1JOBh2FXveuTNuCPYQ0NBIMeta || {},
    component: () => import("/src/pages/dashboard/paylink/[paylink_id].vue")
  },
  {
    name: "dashboard-settings-class-index",
    path: "/dashboard/settings/class-index",
    meta: class_45indexlZKSClaO_45hXjHAA65VAThIZpLofuWuBdmMutNjs2vKoMeta || {},
    component: () => import("/src/pages/dashboard/settings/class-index.vue")
  },
  {
    name: "dashboard-settings-groups-id",
    path: "/dashboard/settings/groups/:id()",
    meta: _91id_93otnYLbKvklJ1XgZt1KIGBGPnajgBs_xHFIWk5o4R2eEMeta || {},
    component: () => import("/src/pages/dashboard/settings/groups/[id].vue")
  },
  {
    name: "dashboard-finance-wallets",
    path: "/dashboard/finance/wallets",
    meta: indexHC2pMwrnxgB2BXWh8yjZ7MrphB4X8vFTZ11VA_r93NgMeta || {},
    component: () => import("/src/pages/dashboard/finance/wallets/index.vue")
  },
  {
    name: "dashboard-rewards-point-history",
    path: "/dashboard/rewards/point-history",
    meta: point_45historyN0vyiEOchNpPxc3q4SV3LmoJSzrbccvBj34rtQW6m0oMeta || {},
    component: () => import("/src/pages/dashboard/rewards/point-history.vue")
  },
  {
    name: "dashboard-settings-address-book",
    path: "/dashboard/settings/address-book",
    meta: address_45book3LqU7akQa_453PgqtqvXYn5xl4pVTx4zFM10ZOJJVT2McMeta || {},
    component: () => import("/src/pages/dashboard/settings/address-book.vue")
  },
  {
    name: "dashboard-settings-groups",
    path: "/dashboard/settings/groups",
    meta: indexlqZbK3sWptzmS3MR3up_45n05B_JXMlJC8ZKBdM3iH4p0Meta || {},
    component: () => import("/src/pages/dashboard/settings/groups/index.vue")
  },
  {
    name: "dashboard-settings-subscription",
    path: "/dashboard/settings/subscription",
    meta: subscriptionbpv_T6ZHRcz_45N_45BU44fpjY1xLAuaUv7x_nYBK2ZIv7IMeta || {},
    component: () => import("/src/pages/dashboard/settings/subscription.vue")
  },
  {
    name: "dashboard-admin-billing-accounts",
    path: "/dashboard/admin/billing-accounts",
    meta: billing_45accountsIXwmtHqp4tkA8vIZq4jiSW6u_455XRDew5CSRfDCdzIh8Meta || {},
    component: () => import("/src/pages/dashboard/admin/billing-accounts.vue")
  },
  {
    name: "dashboard-paylink-create-paylink",
    path: "/dashboard/paylink/create-paylink",
    meta: create_45paylinkcQcfPDd8Ckn4QREgCMLGv2rxNLr6kHByWiqn_DANUTsMeta || {},
    component: () => import("/src/pages/dashboard/paylink/create-paylink.vue")
  },
  {
    name: "dashboard-payments-authenticator",
    path: "/dashboard/payments/authenticator",
    meta: authenticatoru3HKZYFfcM3TQZbz_45kw8FMnDhe4oTIZuYinE1p52ip4Meta || {},
    component: () => import("/src/pages/dashboard/payments/authenticator.vue")
  },
  {
    name: "dashboard-payments-invoices",
    path: "/dashboard/payments/invoices",
    meta: indexwU4P0tjcSgamhqcBlAt_452w95Yaxik409pDULp94gIIQMeta || {},
    component: () => import("/src/pages/dashboard/payments/invoices/index.vue")
  },
  {
    name: "dashboard-onboarding-create-school",
    path: "/dashboard/onboarding/create-school",
    component: () => import("/src/pages/dashboard/onboarding/create-school.vue")
  },
  {
    name: "dashboard-bursary-collections",
    path: "/dashboard/bursary/collections",
    meta: indexkWwT1Y4AmP30gF6ZxxovksbLTbW7Ziw5Gt5KevL1Gp8Meta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/index.vue")
  },
  {
    name: "dashboard-payments-settlement",
    path: "/dashboard/payments/settlement",
    meta: index6dyEHYRyvmyWkSLN0TFQVUzLX0rVHbrSLY53xam6vKAMeta || {},
    component: () => import("/src/pages/dashboard/payments/settlement/index.vue")
  },
  {
    name: "dashboard-settings-groups-migration",
    path: "/dashboard/settings/groups/migration",
    meta: migrationao_uPJGekUIDgPUkKqZu1TCrFxSqAILBB5Eou9KoZhgMeta || {},
    component: () => import("/src/pages/dashboard/settings/groups/migration.vue")
  },
  {
    name: "dashboard-settings-staff-groups-id",
    path: "/dashboard/settings/staff-groups/:id()",
    meta: _91id_93_VX3BLuTZzRQEYXezjvJ5TiKACOYPLKK_45Ha2wrgQBrMMeta || {},
    component: () => import("/src/pages/dashboard/settings/staff-groups/[id].vue")
  },
  {
    name: "dashboard-admin-billing-managers-id",
    path: "/dashboard/admin/billing-managers/:id()",
    meta: _91id_93OE0gXU_1AQaYFrCKj7dxJuToeWL_45RfTMPV1x6zKOBOMMeta || {},
    component: () => import("/src/pages/dashboard/admin/billing-managers/[id].vue")
  },
  {
    name: "dashboard-finance-cowri-offer-updates",
    path: "/dashboard/finance/cowri/offer-updates",
    meta: offer_45updatesxXRctbp_CJAdiVbpPB419VjKiOoLzAFpoHnpfXhKuH0Meta || {},
    component: () => import("/src/pages/dashboard/finance/cowri/offer-updates.vue")
  },
  {
    name: "dashboard-settings-staff-groups",
    path: "/dashboard/settings/staff-groups",
    meta: indexUs7FqKm3_4528QmA684IJiQ1nu_JK9ofXHTMkVwYWgiKoMeta || {},
    component: () => import("/src/pages/dashboard/settings/staff-groups/index.vue")
  },
  {
    name: "dashboard-admin-billing-managers",
    path: "/dashboard/admin/billing-managers",
    meta: index_45lGH1b_45RTF_45RulYe1ji1l1s3AXx8Rz7dyk_45yjA4D39MMeta || {},
    component: () => import("/src/pages/dashboard/admin/billing-managers/index.vue")
  },
  {
    name: "dashboard-bursary-billing-system",
    path: "/dashboard/bursary/billing-system",
    meta: indexQJdxsg5kj2_J_LNcfBoyXmJVN7dzBfVHAuKDcXfn5SMMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/index.vue")
  },
  {
    name: "dashboard-bursary-collections-invoices",
    path: "/dashboard/bursary/collections/invoices",
    meta: invoicesJTcmPPSx8uWhwP_8YOn4dO0fMOFI_45V7Ftn9dsoBUmEwMeta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/invoices.vue")
  },
  {
    name: "dashboard-finance-cowri-current-credit",
    path: "/dashboard/finance/cowri/current-credit",
    meta: current_45credit_85tesC6nDNTOeMJWcEGG8zJvSLksjHCHfEwHNeiqdwMeta || {},
    component: () => import("/src/pages/dashboard/finance/cowri/current-credit.vue")
  },
  {
    name: "dashboard-settings-vendor-groups",
    path: "/dashboard/settings/vendor-groups",
    meta: indexDJtg9yj5Iy62CKlsTiKJwyKx79kPIf6P0yUGZ2hFKsQMeta || {},
    component: () => import("/src/pages/dashboard/settings/vendor-groups/index.vue")
  },
  {
    name: "dashboard-bursary-collections-edit-invoice",
    path: "/dashboard/bursary/collections/edit-invoice",
    meta: edit_45invoiceWYhGHJksTobZEqU_457S39R9r0IOj_45ghT0y_45_tnPPZpa4Meta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/edit-invoice.vue")
  },
  {
    name: "dashboard-bursary-discount-and-scholarship",
    path: "/dashboard/bursary/discount-and-scholarship",
    meta: discount_45and_45scholarshipP3J7Ouhkmjqr7XOO61Tn5_45z4gzsZm2JURiPHvwiRRGYMeta || {},
    component: () => import("/src/pages/dashboard/bursary/discount-and-scholarship.vue")
  },
  {
    name: "dashboard-bursary-collections-create-invoice",
    path: "/dashboard/bursary/collections/create-invoice",
    meta: create_45invoicewLMUTmtqCMMQYmeEVYJLsy0tgm6UQ0klEVfih8pJizwMeta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/create-invoice.vue")
  },
  {
    name: "dashboard-payments-settlement-settlement-list",
    path: "/dashboard/payments/settlement/settlement-list",
    meta: settlement_45listXIZVcsjCuhssxuZkLq2IQIqd5yIO2T269vw2xhhImjMMeta || {},
    component: () => import("/src/pages/dashboard/payments/settlement/settlement-list.vue")
  },
  {
    name: "dashboard-bursary-billing-system-billing-items",
    path: "/dashboard/bursary/billing-system/billing-items",
    meta: billing_45itemsYciZ9INmOTKfdU18Q5nyAyCcAa0LExNCwCR2gfsEG2AMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/billing-items.vue")
  },
  {
    name: "dashboard-bursary-billing-system-add-billing-category",
    path: "/dashboard/bursary/billing-system/add-billing-category",
    meta: add_45billing_45categoryKn62Bw_aotXKPhzEBDPXWPKlbOYQiKu5ZkgnlpG54Z4Meta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/add-billing-category.vue")
  },
  {
    name: "dashboard-bursary-billing-system-edit-billing-category",
    path: "/dashboard/bursary/billing-system/edit-billing-category",
    meta: edit_45billing_45category_45hm3l_45xYx4VSguJQ5dO2CDAQe9KvGIcGa3r2ptuFMdEMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/edit-billing-category.vue")
  }
]