export const routeRoles: Record<string, string[]> = {
  // Finance
  "/dashboard/finance/wallets": ["super_administrator", "administrator"],
  "/dashboard/finance/wallets/cash": ["super_administrator", "administrator"],
  "/dashboard/finance/transaction": ["super_administrator", "administrator"],

  //  Settings
  "/dashboard/settings/compliance": ["super_administrator", "administrator"],
  "/dashboard/settings/payout": ["super_administrator", "administrator"],
  "/dashboard/settings/team": [
    "super_administrator",
    "administrator",
    "bursar",
  ],
  "/dashboard/settings/team/roles": [
    "super_administrator",
    "administrator",
    "bursar",
  ],
  "/dashboard/settings/address-book": ["super_administrator", "administrator"],
};
