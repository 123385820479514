import revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0 from "/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ from "/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI from "/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hooks_mcK_4g7FbGZ_QsZXhSiviqpTkn7_8yN1jJQhug0cQ40 from "/node_modules/nuxt/dist/app/plugins/debug-hooks.js";
import payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q from "/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0 from "/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I from "/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk from "/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0 from "/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8 from "/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Vvz9_IiMZIZhB6QFmqkkJX6fn08Q12b4cuX0CSn11Ew from "/node_modules/nuxt-permissions/dist/runtime/plugin.mjs";
import axios_nKQInGxkjkGyJUCE2J7W14opqRZSZPNVns2t0UtSgnM from "/src/plugins/axios.ts";
import plugin_a_m9THTX31SZZNAiEBk0yWBtQGpy6zcC3iVETObM2G8 from "/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import ably_client_ULPJ959IruWHIeT1Dqn4x3_UNsp1H0R4otoHGzxJn3Q from "/src/plugins/ably.client.ts";
import apexcharts_client_iQZE5FwVjxRuETiXWNYYc34Cke3u9yXBig_upYiBWuA from "/src/plugins/apexcharts.client.ts";
import formatCurrency_zYhBFAT9xDifd2NSc4_ZmSAXctlHZwShHFliUbMRUAU from "/src/plugins/formatCurrency.ts";
import mitt_client_P1ijbmT4qeR_6ll_hNAEj3rshSh5avHJZ_eeaQWff_g from "/src/plugins/mitt.client.ts";
import moment_MM_SQYH1Mj0bweG3HLyd0__CJ3_2kDyK173WO8AQodE from "/src/plugins/moment.js";
import saveToPdf_ksQlJ0ga0_7a6xwiFLZZkyk03bnw8L6YtNRqyMjkuf0 from "/src/plugins/saveToPdf.ts";
import saveToPdfTwo_yeagQ3zXTzXMxkJZ23vAiQiar4XmCu9E3ZDGQjjdnhQ from "/src/plugins/saveToPdfTwo.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/src/plugins/sentry.client.ts";
import toast_NXZutKzSxTrwufKuwFUzGGb_RAolsBg_NAsOw6HG3ew from "/src/plugins/toast.js";
import v_tooltip_client_QcyKzwJYLuiE8_jOVtWrWH4fBJX1CpCasAnqzRRzXUg from "/src/plugins/v-tooltip.client.ts";
import vuetelinput_i0iifkZROB5aZh2EejDgaRo4TFXjWrOHo_PwCGN8__g from "/src/plugins/vuetelinput.js";
export default [
  revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0,
  unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ,
  router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI,
  debug_hooks_mcK_4g7FbGZ_QsZXhSiviqpTkn7_8yN1jJQhug0cQ40,
  payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q,
  navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0,
  check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I,
  chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk,
  plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8,
  plugin_Vvz9_IiMZIZhB6QFmqkkJX6fn08Q12b4cuX0CSn11Ew,
  axios_nKQInGxkjkGyJUCE2J7W14opqRZSZPNVns2t0UtSgnM,
  plugin_a_m9THTX31SZZNAiEBk0yWBtQGpy6zcC3iVETObM2G8,
  ably_client_ULPJ959IruWHIeT1Dqn4x3_UNsp1H0R4otoHGzxJn3Q,
  apexcharts_client_iQZE5FwVjxRuETiXWNYYc34Cke3u9yXBig_upYiBWuA,
  formatCurrency_zYhBFAT9xDifd2NSc4_ZmSAXctlHZwShHFliUbMRUAU,
  mitt_client_P1ijbmT4qeR_6ll_hNAEj3rshSh5avHJZ_eeaQWff_g,
  moment_MM_SQYH1Mj0bweG3HLyd0__CJ3_2kDyK173WO8AQodE,
  saveToPdf_ksQlJ0ga0_7a6xwiFLZZkyk03bnw8L6YtNRqyMjkuf0,
  saveToPdfTwo_yeagQ3zXTzXMxkJZ23vAiQiar4XmCu9E3ZDGQjjdnhQ,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  toast_NXZutKzSxTrwufKuwFUzGGb_RAolsBg_NAsOw6HG3ew,
  v_tooltip_client_QcyKzwJYLuiE8_jOVtWrWH4fBJX1CpCasAnqzRRzXUg,
  vuetelinput_i0iifkZROB5aZh2EejDgaRo4TFXjWrOHo_PwCGN8__g
]